import React from 'react';
import {Link} from 'react-router-dom';

import {relayConnectionQuery} from '@sphericsio/mvp-ui-common';

import {DataTable} from '../../components/table';
import {MerchantTabs} from '../../components/merchants/tabs';
import {DateDisplay} from '../../components/date-display';
import {graphql} from '../../graphql/generated';
import {GetUnidentifiedMerchantsQuery} from '../../graphql/generated/graphql';

const GetUnidentifiedMerchantsDocument = graphql(/* GraphQL */ `
    query GetUnidentifiedMerchants(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $orderBy: UnidentifiedMerchantsOrder!
        $orderDirection: SortDirection!
    ) {
        getAllUnidentifiedMerchants(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: $orderBy
            orderDirection: $orderDirection
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    name
                    created_at
                }
            }
        }
    }
`);

const UnidentifiedMerchants: React.FC = () => {
    const queryConfig = relayConnectionQuery(
        GetUnidentifiedMerchantsDocument,
        (data: GetUnidentifiedMerchantsQuery) => data.getAllUnidentifiedMerchants,
        {},
    );
    return (
        <MerchantTabs tab="unidentified">
            <DataTable
                queryConfig={queryConfig}
                columns={[
                    {
                        label: 'Name',
                        // eslint-disable-next-line react/display-name
                        render: (value) => <Link to={`/merchants/${value.id}`}>{value.name}</Link>,
                    },
                    {
                        label: 'Created',
                        key: 'created_at',
                        // eslint-disable-next-line react/display-name
                        renderValue: (value) => <DateDisplay date={value} />,
                    },
                ]}
                sortableFields={['id', 'name']}
            />
        </MerchantTabs>
    );
};

export {UnidentifiedMerchants};
