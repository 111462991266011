import {ApolloClient, HttpLink, InMemoryCache, from} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';

import {clearCurrentUser} from '@sphericsio/mvp-ui-common';

import config from '../../config';
import {storeRedirectPath} from './login-redirect';
import {TypedTypePolicies} from '../graphql/generated/graphql';

const typePolicies: TypedTypePolicies = {
    AdminMerchant: {
        fields: {
            transactions: relayStylePagination(['orderBy', 'orderDirection']),
        },
    },
    Query: {
        fields: {
            getAllUnidentifiedMerchants: relayStylePagination(['orderBy', 'orderDirection']),
            getTransactionsWithoutMerchants: relayStylePagination(['orderBy', 'orderDirection']),
        },
    },
};

export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies,
    }),
    link: from([
        onError((error) => {
            const unauthenticatedErrors = error.graphQLErrors?.filter(
                (gqlError) => gqlError.extensions?.code === 'UNAUTHENTICATED',
            );

            if (unauthenticatedErrors != null && unauthenticatedErrors.length > 0) {
                storeRedirectPath();
                clearCurrentUser(client.cache);
            }
        }),
        new HttpLink({
            uri: `${config.apiHost}/admin/graphql`,
            credentials: 'include',
        }),
    ]),
});
