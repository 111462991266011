import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useQuery, useApolloClient} from '@apollo/client';

import {Heading2, TabBar, TabItemConfig} from '@sphericsio/design-system';

import {View} from '../../components/view';
import {Loading} from '../../components/loading';
import {ErrorCard} from '../../components/error-card';
import {SearchCompaniesHouse} from '../../components/merchants/search-companies-house';
import {SearchSicCodes} from '../../components/merchants/search-sic-codes';
import {MerchantBreadcrumbLink} from '../../components/merchants/breadcrumb-link';
import {AdminMerchantConnection} from '../../graphql/generated/graphql';
import {graphql} from '../../graphql/generated';

const GetMerchantForIdentifyDocument = graphql(/* GraphQL */ `
    query GetMerchantForIdentify($id: ID!) {
        getAdminMerchantById(id: $id) {
            id
            name
        }
    }
`);

const tabConfig: TabItemConfig[] = [
    {
        id: 'companiesHouse',
        label: 'Search Companies House',
    },
    {
        id: 'sicCodes',
        label: 'Search SIC Codes',
    },
];

type IdentifyTabsProps = {
    selectedTab: TabItemConfig;
    onChange: (tab: TabItemConfig) => void;
};
const IdentifyTabs: React.FC<IdentifyTabsProps> = ({selectedTab, onChange}) => {
    return (
        <TabBar selectedTab={selectedTab} tabs={tabConfig} onSelectTab={(tab) => onChange(tab)} />
    );
};

const IdentifyMerchant: React.FC = () => {
    const {merchantId} = useParams();
    const [selectedTab, setSelectedTab] = React.useState(tabConfig[0]);
    const {loading, error, data} = useQuery(GetMerchantForIdentifyDocument, {
        variables: {id: merchantId},
    });
    const navigate = useNavigate();
    const apolloClient = useApolloClient();

    const onIdentified = (merchantId: string) => {
        apolloClient.cache.modify({
            fields: {
                getAllUnidentifiedMerchants: (data: AdminMerchantConnection, {readField}) => {
                    const edges = data.edges.filter(
                        (edge) => readField('id', edge.node) !== merchantId,
                    );
                    return {
                        ...data,
                        edges,
                    };
                },
            },
        });
        navigate(`/merchants/${merchantId}`);
    };

    return (
        <View>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <div className="flex flex-col">
                    <MerchantBreadcrumbLink merchant={data.getAdminMerchantById} />
                    <div className="flex">
                        <div className="flex flex-col mb-4">
                            <Heading2>{data.getAdminMerchantById.name}</Heading2>
                        </div>
                    </div>
                    <IdentifyTabs selectedTab={selectedTab} onChange={setSelectedTab} />
                    <div className="mt-2">
                        {selectedTab.id === 'companiesHouse' && (
                            <SearchCompaniesHouse
                                merchantId={merchantId}
                                onSuccess={onIdentified}
                            />
                        )}
                        {selectedTab.id === 'sicCodes' && (
                            <SearchSicCodes merchantId={merchantId} onSuccess={onIdentified} />
                        )}
                    </div>
                </div>
            )}
        </View>
    );
};

export {IdentifyMerchant};
