import React from 'react';

import {DetailText} from '@sphericsio/design-system';

import {EditMerchantSicCodesModal} from '../modals/edit-sic-codes-modal';
import {MerchantHeaderPanel} from './header-panel';
import {MerchantDetail_AdminMerchantFragment} from '../../graphql/generated/graphql';

type MerchantSicCodesProps = {
    merchant: MerchantDetail_AdminMerchantFragment;
};

export function MerchantSicCodes({merchant}: MerchantSicCodesProps) {
    const [modalMerchantId, setModalMerchantId] = React.useState<string>();
    const showModal = () => {
        setModalMerchantId(merchant.id);
    };
    const onClose = () => {
        setModalMerchantId(undefined);
    };
    return (
        <MerchantHeaderPanel buttonLabel="Edit" onPressButton={showModal}>
            <>
                {merchant &&
                    merchant.sicCodes.map((sicCode) => (
                        <DetailText key={sicCode.sic_code}>
                            ({sicCode.sic_code}) {sicCode.description}
                        </DetailText>
                    ))}
                <EditMerchantSicCodesModal
                    merchantId={modalMerchantId}
                    onSuccess={onClose}
                    onClose={onClose}
                    initialSicCodes={merchant.sicCodes.map((sicCode) => sicCode.sic_code)}
                />
            </>
        </MerchantHeaderPanel>
    );
}
