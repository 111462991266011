import React from 'react';

import {Money} from '../graphql/scalars';

export function moneyAsValue(money: Money) {
    const wholeAmount = parseInt(money[1], 10);
    const decimalAmount = parseInt(money[2], 10);

    if (isNaN(wholeAmount) || isNaN(decimalAmount)) {
        return NaN;
    }

    return wholeAmount + decimalAmount / 10 ** money[2].length;
}

const formatters: Record<string, Intl.NumberFormat> = {};
export function moneyAsText(money: Money) {
    const value = moneyAsValue(money);
    if (isNaN(value)) {
        return 'Invalid amount';
    }

    let formatter = formatters[money[0]];
    if (formatter == null) {
        formatter = formatters[money[0]] = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: money[0],
            minimumFractionDigits: 2,
        });
    }

    return formatter.format(value);
}

type MoneyDisplayProps = {
    money: Money;
};
const MoneyDisplay: React.FC<MoneyDisplayProps> = ({money}) => {
    const value = moneyAsText(money);

    return <>{value}</>;
};

export {MoneyDisplay};
