import React from 'react';

export type MerchantToMerge = {
    id: string;
    name: string;
    identified: boolean;
};

type MergeMerchantContext = {
    setMerchantToMerge: (merchant: MerchantToMerge) => void;
    merchantToMerge?: MerchantToMerge;
    clearMerchantToMerge: () => void;
};

const ctx = React.createContext<MergeMerchantContext>({
    setMerchantToMerge: () => {
        throw new Error('missing MergeMerchantContextProvider');
    },
    clearMerchantToMerge: () => {
        throw new Error('missing MergeMerchantContextProvider');
    },
});

export const MergeMerchantContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [merchantToMerge, setMerchantToMerge] = React.useState<MerchantToMerge>();
    return (
        <ctx.Provider
            value={{
                merchantToMerge,
                setMerchantToMerge,
                clearMerchantToMerge: () => setMerchantToMerge(undefined),
            }}
        >
            {children}
        </ctx.Provider>
    );
};

export const useMergeMerchantContext = () => React.useContext(ctx);
