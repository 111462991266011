import React from 'react';

import {Modal} from './modal';
import {SearchSicCodes} from '../merchants/search-sic-codes';

type EditMerchantSicCodesModalProps = {
    merchantId?: string;
    initialSicCodes: string[];
    onClose: () => void;
    onSuccess: (merchantId: string) => void;
};

const EditMerchantSicCodesModal: React.FC<EditMerchantSicCodesModalProps> = ({
    merchantId,
    initialSicCodes,
    onClose,
    onSuccess,
}) => {
    return (
        <Modal title="Edit Merchant SIC Codes" isOpen={merchantId != null} onClose={onClose}>
            <div style={{width: '80vw'}}>
                {merchantId && (
                    <SearchSicCodes
                        merchantId={merchantId}
                        initialSicCodes={initialSicCodes}
                        onSuccess={onSuccess}
                    />
                )}
            </div>
        </Modal>
    );
};

export {EditMerchantSicCodesModal};
