import React from 'react';

import {Button, DetailText} from '@sphericsio/design-system';

type MerchantHeaderPanelProps = {
    children: React.ReactNode;
    buttonLabel: string;
    onPressButton: () => void;
};
export function MerchantHeaderPanel({
    children,
    buttonLabel,
    onPressButton,
}: MerchantHeaderPanelProps) {
    return (
        <div className="flex flex-col flex-1">
            <div className="flex flex-col flex-1 mb-2">
                <DetailText inline={false}>{children}</DetailText>
            </div>
            <Button onPress={onPressButton}>{buttonLabel}</Button>
        </div>
    );
}
