import React from 'react';

import {Panel, Heading2} from '@sphericsio/design-system';

type FormPanelProps = {
    children: React.ReactNode;
    title?: string | null;
};

const FormPanel: React.FC<FormPanelProps> = ({title, children}) => {
    return (
        <Panel>
            <Heading2>{title ?? ''}</Heading2>
            {children}
        </Panel>
    );
};

export {FormPanel};
