import React from 'react';
import {useQuery, useMutation} from '@apollo/client';

import {Button, DetailText, Strong, VStack, TabBar, HStack} from '@sphericsio/design-system';

import {Loading} from '../loading';
import {ErrorCard} from '../error-card';
import {MerchantTaxonomy} from '../../graphql/generated/graphql';
import {EditMerchantTaxonomyTermsDocument, FetchAllTaxonomiesDocument} from './graphql';

const tabConfig = [
    {
        id: 'override',
        label: 'Overriden',
    },
    {
        id: 'suggested',
        label: 'Suggested',
    },
];

type EditMerchantTaxonomiesProps = {
    merchantId: string;
    merchantTaxonomyTerms: MerchantTaxonomy;
    onSuccess: (merchantId: string) => void;
};

const EditMerchantTaxonomies: React.FC<EditMerchantTaxonomiesProps> = ({
    merchantId,
    merchantTaxonomyTerms,
    onSuccess,
}) => {
    const {
        loading: loadingCategories,
        error: errorFetching,
        data: allTaxonomies,
    } = useQuery(FetchAllTaxonomiesDocument);
    const [currentlySelected, setCurrentlySelected] = React.useState(merchantTaxonomyTerms);
    const [selectedApplyMode, setSelectedApplyMode] = React.useState(tabConfig[1]);
    const toggleTaxonomyTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setCurrentlySelected((v) => {
            if (name === 'override') {
                return {
                    ...v,
                    overrideTaxonomyTerm: value,
                };
            } else {
                return {
                    ...v,
                    suggestedTaxonomyTerm: value,
                };
            }
        });
    };
    const clearSelected = () => {
        setCurrentlySelected((v) => {
            if (selectedApplyMode.id === 'override') {
                return {
                    ...v,
                    overrideTaxonomyTerm: undefined,
                };
            } else {
                return {
                    ...v,
                    suggestedTaxonomyTerm: undefined,
                };
            }
        });
    };
    const [submit, {loading, error, data}] = useMutation(EditMerchantTaxonomyTermsDocument);

    const onSubmit = () => {
        submit({
            variables: {
                ...currentlySelected,
                merchantId,
            },
        });
    };

    React.useEffect(() => {
        if (data != null) {
            onSuccess(data.editMerchantTaxonomyTerms.id);
        }
    }, [data, onSuccess]);

    return (
        <VStack>
            {loadingCategories && <Loading />}
            {errorFetching && <ErrorCard />}
            {allTaxonomies && (
                <>
                    <TabBar
                        selectedTab={selectedApplyMode}
                        tabs={tabConfig}
                        onSelectTab={(tabItem) => setSelectedApplyMode(tabItem)}
                    />
                    {allTaxonomies.getAllTaxonomies.map((taxonomy) => (
                        <DetailText inline={false} key={taxonomy.id}>
                            <div className="text-left">
                                <Strong>{taxonomy.name}</Strong>
                            </div>
                            <ul>
                                {taxonomy.terms.map((term) => (
                                    <li key={term.id}>
                                        <div className="flex">
                                            <div className="flex flex-1 mr-2">{term.name}</div>
                                            <div className="flex items-center">
                                                <input
                                                    type="radio"
                                                    name={selectedApplyMode.id}
                                                    value={term.id}
                                                    checked={
                                                        selectedApplyMode.id === 'override'
                                                            ? currentlySelected.overrideTaxonomyTerm ===
                                                              term.id
                                                            : currentlySelected.suggestedTaxonomyTerm ===
                                                              term.id
                                                    }
                                                    onChange={toggleTaxonomyTerm}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </DetailText>
                    ))}
                </>
            )}

            <HStack>
                <Button isLoading={loading} onPress={onSubmit}>
                    Submit
                </Button>
                <Button bg="major-action" onPress={clearSelected}>
                    Clear {selectedApplyMode.label}
                </Button>
            </HStack>
            {error && <ErrorCard />}
        </VStack>
    );
};

export {EditMerchantTaxonomies};
