import React from 'react';

import {Heading2} from '@sphericsio/design-system';

import {MerchantMatcherRow} from './matcher-row';
import {Table, THead, THeader, TBody, TRow, TData} from '../table';
import {FormPanel} from '../form-panel';
import {MerchantAddMatcher} from './add-matcher';
import {MerchantDetail_AdminMerchantFragment} from '../../graphql/generated/graphql';

type MerchantMatchersProps = {
    merchant: MerchantDetail_AdminMerchantFragment;
};

export function MerchantMatchers({merchant}: MerchantMatchersProps) {
    return (
        <div className="flex flex-col">
            <Heading2>Matchers</Heading2>
            <div className="flex">
                <div className="w-1/3 mr-4">
                    <FormPanel title="Add Transaction Matcher">
                        <MerchantAddMatcher merchantId={merchant.id} />
                    </FormPanel>
                </div>
                <Table>
                    <THead>
                        <TRow>
                            <THeader>Term</THeader>
                            <THeader textAlign="center">Enabled</THeader>
                            <THeader textAlign="center">Notes?</THeader>
                        </TRow>
                    </THead>
                    <TBody>
                        {merchant.matchers.map((matcher) => (
                            <MerchantMatcherRow key={matcher.id} matcher={matcher} />
                        ))}
                        {merchant.matchers.length === 0 && (
                            <TRow>
                                <TData colSpan={3}>No matchers for this merchant.</TData>
                            </TRow>
                        )}
                    </TBody>
                </Table>
            </div>
        </div>
    );
}
