import React from 'react';
import {useQuery} from '@apollo/client';

import {Heading2, Button} from '@sphericsio/design-system';
import {relayConnectionQuery} from '@sphericsio/mvp-ui-common';

import {DataTable} from '../components/table';
import {moneyAsText} from '../components/money';
import {View} from '../components/view';
import {CreateMerchantFromTransactionModal} from '../components/modals/create-merchant-from-transaction';
import {FinancialAccountDisplay} from '../components/merchants/financial-account-display';
import {DateDisplay} from '../components/date-display';
import {graphql} from '../graphql/generated';
import {GetTransactionsWithoutMerchantsQuery} from '../graphql/generated/graphql';

const GetTransactionsWithoutMerchantsDocument = graphql(/* GraphQL */ `
    query GetTransactionsWithoutMerchants(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $orderBy: TransactionOrder!
        $orderDirection: SortDirection!
    ) {
        getTransactionsWithoutMerchants(
            first: $first
            after: $after
            last: $last
            before: $before
            orderBy: $orderBy
            orderDirection: $orderDirection
        ) {
            pageInfo {
                hasNextPage
                endCursor
                hasPreviousPage
                startCursor
            }
            edges {
                node {
                    id
                    description
                    amount
                    transaction_date
                    ...FinancialAccountDisplay_AdminTransaction
                }
            }
        }
    }
`);

const GetAllTaxonomiesDocument = graphql(/* GraphQL */ `
    query GetAllTaxonomies {
        getAllTaxonomies {
            ...TaxonomyTermDisplay_Taxonomy
        }
    }
`);

const TransactionsWithoutMerchants: React.FC = () => {
    const {data} = useQuery(GetAllTaxonomiesDocument);
    const queryConfig = relayConnectionQuery(
        GetTransactionsWithoutMerchantsDocument,
        (data: GetTransactionsWithoutMerchantsQuery) => data.getTransactionsWithoutMerchants,
    );
    const [transactionId, setTransactionId] = React.useState<string>();

    function onPressCreateMerchant(transactionId: string) {
        setTransactionId(transactionId);
    }

    function onClose() {
        setTransactionId(undefined);
    }

    return (
        <View>
            <div className="flex flex-col mb-4">
                <Heading2>Transactions</Heading2>
                <DataTable
                    queryConfig={queryConfig}
                    columns={[
                        {label: 'Description', key: 'description'},
                        {
                            label: 'Amount',
                            key: 'amount',
                            renderValue: (value) => moneyAsText(value),
                        },
                        {
                            label: 'Date',
                            key: 'transaction_date',
                            // eslint-disable-next-line react/display-name
                            renderValue: (value) => <DateDisplay date={value} />,
                        },
                        {
                            label: 'Account',
                            // eslint-disable-next-line react/display-name
                            render: (value) => (
                                <FinancialAccountDisplay
                                    transaction={value}
                                    taxonomies={data?.getAllTaxonomies}
                                />
                            ),
                        },
                        {
                            label: 'Action',
                            // eslint-disable-next-line react/display-name
                            render: (txn) => (
                                <Button onPress={() => onPressCreateMerchant(txn.id)}>
                                    Create Merchant
                                </Button>
                            ),
                        },
                    ]}
                    sortableFields={['transaction_date', 'amount', 'description']}
                />
            </div>
            <CreateMerchantFromTransactionModal transactionId={transactionId} onClose={onClose} />
        </View>
    );
};

export {TransactionsWithoutMerchants};
