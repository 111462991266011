import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Modal} from './modal';
import {CreateMerchantFromTransactionForm} from '../create-merchant-from-transaction-form';

type CreateMerchantFromTransactionModalProps = {
    transactionId?: string;
    onClose: () => void;
};

const CreateMerchantFromTransactionModal: React.FC<CreateMerchantFromTransactionModalProps> = ({
    transactionId,
    onClose,
}) => {
    const navigate = useNavigate();
    return (
        <Modal title="Create Merchant" isOpen={transactionId != null} onClose={onClose}>
            {transactionId && (
                <CreateMerchantFromTransactionForm
                    transactionId={transactionId}
                    onSuccess={(merchantId) => {
                        onClose();
                        navigate(`/merchant/${merchantId}`);
                    }}
                />
            )}
        </Modal>
    );
};

export {CreateMerchantFromTransactionModal};
