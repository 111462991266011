import React from 'react';
import {useMutation} from '@apollo/client';

import {FormControl, Input, Button, VStack} from '@sphericsio/design-system';

import {graphql} from '../graphql/generated';

const CreateMerchantFromTransactionDocument = graphql(/* GraphQL */ `
    mutation CreateMerchantFromTransaction($transactionId: ID!, $name: String!) {
        createMerchantFromTransaction(transactionId: $transactionId, name: $name) {
            id
            name
        }
    }
`);

type CreateMerchantFromTransactionFormProps = {
    transactionId: string;
    onSuccess?: (merchantId: string) => void;
};

const CreateMerchantFromTransactionForm: React.FC<CreateMerchantFromTransactionFormProps> = ({
    transactionId,
    onSuccess,
}) => {
    const [name, setName] = React.useState('');
    const [createMerchant, {loading, data}] = useMutation(CreateMerchantFromTransactionDocument);
    React.useEffect(() => {
        if (data != null && onSuccess != null) {
            onSuccess(data.createMerchantFromTransaction.id);
        }
    }, [data, onSuccess]);
    return (
        <form
            onSubmit={(event: React.FormEvent) => {
                event.preventDefault();
                if (name === '') {
                    return;
                }

                createMerchant({
                    variables: {transactionId, name},
                });
            }}
        >
            <VStack>
                <FormControl>
                    <Input
                        name="merchantName"
                        placeholder="Merchant name"
                        value={name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setName(event.target.value)
                        }
                    />
                </FormControl>
                <Button type="submit" isLoading={loading}>
                    Create Merchant
                </Button>
            </VStack>
        </form>
    );
};

export {CreateMerchantFromTransactionForm};
