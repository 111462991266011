import React from 'react';

import {FormControl, Input, Button, Textarea, VStack} from '@sphericsio/design-system';

type AddMatcherProps = {
    addMatcher: (matcher: string, enabled: true, notes?: string) => void;
    loading: boolean;
};

const AddMatcher: React.FC<AddMatcherProps> = ({addMatcher, loading}) => {
    const [matcher, setMatcher] = React.useState('');
    const [notes, setNotes] = React.useState('');

    return (
        <form
            onSubmit={(event: React.FormEvent) => {
                event.preventDefault();
                if (matcher === '') {
                    return;
                }

                addMatcher(matcher, true, notes === '' ? undefined : notes);

                setMatcher('');
                setNotes('');
            }}
        >
            <VStack>
                <FormControl>
                    <Input
                        name="matcher"
                        placeholder="Matcher"
                        value={matcher}
                        onChange={(event) => setMatcher(event.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <Textarea
                        name="notes"
                        placeholder="Notes?"
                        value={notes}
                        onChange={(event) => setNotes(event.target.value)}
                    />
                </FormControl>
                <div>
                    <Button type="submit" isLoading={loading}>
                        Add Matcher
                    </Button>
                </div>
            </VStack>
        </form>
    );
};

export {AddMatcher};
