import React from 'react';
import {useMutation} from '@apollo/client';

import {DetailText, FormControl, FormErrorMessage} from '@sphericsio/design-system';

import {
    MerchantDetail_AdminMerchantFragment,
    MerchantMetadataType,
} from '../../graphql/generated/graphql';
import {SetMerchantMetadataFlagDocument} from './graphql';

const METADATA_TYPES: {label: string; value: MerchantMetadataType}[] = [
    {
        label: 'Is Airline',
        value: 'airline',
    },
    {
        label: 'Staff Member',
        value: 'staff_member',
    },
    {
        label: 'Vehicle Fuel Supplier',
        value: 'vehicle_fuel_supplier',
    },
    {
        label: 'GHG Supplier',
        value: 'ghg_supplier',
    },
];

type MetadataToggleProps = {
    label: string;
    type: MerchantMetadataType;
    id?: string;
    addMetadata: (type: MerchantMetadataType) => void;
    removeMetadata: (id: string) => void;
    loading: boolean;
};

const MetadataToggle: React.FC<MetadataToggleProps> = ({
    type,
    label,
    id,
    addMetadata,
    removeMetadata,
    loading,
}) => {
    return (
        <DetailText inline={false}>
            <label>{label}</label>
            <input
                className="ml-2"
                type="checkbox"
                checked={id != null}
                disabled={loading}
                onChange={() => {
                    if (id != null) {
                        removeMetadata(id);
                    } else {
                        addMetadata(type);
                    }
                }}
            />
        </DetailText>
    );
};

type MetadataFlagsProps = {
    merchant: MerchantDetail_AdminMerchantFragment;
};

export function MetadataFlags({merchant}: MetadataFlagsProps) {
    const [setMetadata, {loading}] = useMutation(SetMerchantMetadataFlagDocument);
    const addMetadata = (type: MerchantMetadataType) => {
        setMetadata({variables: {params: {merchant_id: merchant.id, metadata_type: type}}});
    };
    const removeMetadata = (id: string) => {
        setMetadata({variables: {params: {merchant_id: merchant.id, id, remove: true}}});
    };
    return (
        <form>
            <FormControl isInvalid={false}>
                {METADATA_TYPES.map((type) => {
                    const value = merchant.metadata.find((m) => type.value === m.metadata_type);
                    return (
                        <MetadataToggle
                            key={type.value}
                            type={type.value}
                            label={type.label}
                            id={value?.id}
                            addMetadata={addMetadata}
                            removeMetadata={removeMetadata}
                            loading={loading}
                        />
                    );
                })}
                <FormErrorMessage>An error occurred, please try again later.</FormErrorMessage>
            </FormControl>
        </form>
    );
}
