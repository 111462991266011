import React from 'react';
import {useMutation} from '@apollo/client';

import {AddMatcher} from '../matchers/add-matcher';
import {AddMatcherToMerchantDocument} from './graphql';

type MerchantAddMatcherProps = {
    merchantId: string;
};
const MerchantAddMatcher: React.FC<MerchantAddMatcherProps> = ({merchantId}) => {
    const [addMatcher, {loading}] = useMutation(AddMatcherToMerchantDocument);
    return (
        <AddMatcher
            addMatcher={(matcher, enabled, notes) =>
                addMatcher({
                    variables: {merchantId, matcher: {matcher, enabled, notes}},
                })
            }
            loading={loading}
        />
    );
};

export {MerchantAddMatcher};
