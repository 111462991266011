import React from 'react';
import {getYear} from 'date-fns';
import {useMutation} from '@apollo/client';

import {FormControl, Input, Button, DetailText, VStack} from '@sphericsio/design-system';

import {ErrorCard} from '../error-card';
import {AddMerchantCarbonIntensityDocument} from './graphql';
import {useFragment} from '../../graphql/generated';
import {MerchantDetailAdminMerchantFragmentDocument} from '../../views/merchant-detail';

const EARLIEST_YEAR = 1900;
const CURRENT_YEAR = getYear(new Date());

type AddMerchantCarbonIntensityProps = {
    merchantId: string;
    onSuccess: (merchantId: string) => void;
};

const AddMerchantCarbonIntensity: React.FC<AddMerchantCarbonIntensityProps> = ({
    merchantId,
    onSuccess,
}) => {
    const [sourceYear, setSourceYear] = React.useState('');
    const [sourceUrl, setSourceUrl] = React.useState('');
    const [carbonIntensityValue, setCarbonIntensityValue] = React.useState('');
    const [showFeedback, setShowFeedback] = React.useState(false);
    const [submit, {loading, error, data}] = useMutation(AddMerchantCarbonIntensityDocument);

    const isSourceYearValid = () => {
        const sourceYearAsNumber = parseInt(sourceYear);
        return (
            !isNaN(sourceYearAsNumber) &&
            sourceYearAsNumber > EARLIEST_YEAR &&
            sourceYearAsNumber <= CURRENT_YEAR
        );
    };

    const isSourceUrlValid = () => {
        return sourceUrl != '';
    };

    const isCarbonIntensityValueValid = () => {
        const valueAsNumber = parseFloat(carbonIntensityValue);
        return !isNaN(valueAsNumber) && valueAsNumber > 0;
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setShowFeedback(true);
        const valid = isSourceYearValid() && isSourceUrlValid() && isCarbonIntensityValueValid();
        if (valid) {
            submit({
                variables: {
                    params: {
                        merchantId,
                        sourceYear: parseInt(sourceYear),
                        sourceUrl,
                        value: parseFloat(carbonIntensityValue),
                    },
                },
            });
        }
    };

    React.useEffect(() => {
        if (data != null) {
            const res = useFragment(
                MerchantDetailAdminMerchantFragmentDocument,
                data.addMerchantCarbonIntensity,
            );
            onSuccess(res.id);
        }
    }, [data, onSuccess]);

    return (
        <form onSubmit={onSubmit}>
            <VStack>
                <FormControl isInvalid={showFeedback && !isSourceYearValid()}>
                    <Input
                        name="sourceYear"
                        placeholder="Source year"
                        value={sourceYear}
                        onChange={(e) => setSourceYear(e.target.value)}
                    />
                </FormControl>
                <FormControl isInvalid={showFeedback && !isSourceUrlValid()}>
                    <Input
                        name="sourceUrl"
                        placeholder="Source url"
                        value={sourceUrl}
                        onChange={(e) => setSourceUrl(e.target.value)}
                    />
                </FormControl>
                <FormControl isInvalid={showFeedback && !isCarbonIntensityValueValid()}>
                    <div className="flex">
                        <Input
                            name="carbonIntensity"
                            placeholder="Carbon intensity value"
                            value={carbonIntensityValue}
                            onChange={(e) => setCarbonIntensityValue(e.target.value)}
                        />
                        <DetailText>KG CO2e per £</DetailText>
                    </div>
                </FormControl>
                <Button type="submit" isLoading={loading}>
                    Submit
                </Button>
                {error && <ErrorCard />}
            </VStack>
        </form>
    );
};

export {AddMerchantCarbonIntensity};
