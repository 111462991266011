import React from 'react';
import {useNavigate} from 'react-router-dom';

import {TabBar} from '@sphericsio/design-system';

import {View} from '../view';

const tabConfig = [
    {
        path: '/merchants/unidentified',
        itemConfig: {
            id: 'unidentified',
            label: 'Unidentified Merchants',
        },
    },
    {
        path: '/merchants/search',
        itemConfig: {
            id: 'search',
            label: 'Search',
        },
    },
];

type MerchantsTabsProps = {
    children: React.ReactNode;
    tab: 'unidentified' | 'search';
};

const MerchantTabs: React.FC<MerchantsTabsProps> = ({tab, children}) => {
    const navigate = useNavigate();
    const selectedTab = tabConfig.find((item) => item.itemConfig.id === tab) ?? tabConfig[0];
    return (
        <View>
            <TabBar
                selectedTab={selectedTab.itemConfig}
                tabs={tabConfig.map((item) => item.itemConfig)}
                onSelectTab={(tab) => {
                    const item = tabConfig.find((item) => item.itemConfig.id === tab.id);
                    if (item != null) {
                        navigate(item.path);
                    }
                }}
            />

            <div className="flex flex-col mt-2">{children}</div>
        </View>
    );
};

export {MerchantTabs};
