import React from 'react';

import {Matcher} from '../../graphql/generated/graphql';
import {TRow, TData} from '../table';

type MatcherRowProps = {
    matcher: Matcher;
    updateMatcher: (id: string, enabled: boolean) => void;
    loading: boolean;
};

const MatcherRow: React.FC<MatcherRowProps> = (props) => {
    const {matcher, updateMatcher, loading} = props;
    const updateEnabled: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        updateMatcher(matcher.id, event.target.checked);
    };

    return (
        <TRow key={matcher.id}>
            <TData>
                <samp>{matcher.query}</samp>
            </TData>
            <TData textAlign="center">
                <input
                    type="checkbox"
                    checked={matcher.enabled}
                    disabled={loading}
                    onChange={updateEnabled}
                />
            </TData>
            <TData textAlign="center">
                {matcher.notes && (
                    <div
                        title={matcher.notes}
                        className="w-full overflow-x-hidden overflow-ellipsis whitespace-nowrap max-w-xs"
                    >
                        {matcher.notes}
                    </div>
                )}
            </TData>
        </TRow>
    );
};

export {MatcherRow};
