import React from 'react';

import {Chip, DetailText} from '@sphericsio/design-system';

import {graphql} from '../graphql/generated';
import {TaxonomyTermDisplay_TaxonomyFragment} from '../graphql/generated/graphql';

export const TaxonomyTermDisplayTaxonomyFragmentDocument = graphql(/* GraphQL */ `
    fragment TaxonomyTermDisplay_Taxonomy on Taxonomy {
        id
        name
        terms {
            id
            name
        }
    }
`);

function getTaxonomyTermDetails(
    taxonomyTermKey: string,
    taxonomies?: TaxonomyTermDisplay_TaxonomyFragment[] | null,
) {
    for (const taxonomy of taxonomies ?? []) {
        const term = taxonomy.terms.find((term) => term.id === taxonomyTermKey);

        if (term != null) {
            return {
                taxonomyName: taxonomy.name,
                taxonomyTermName: term.name,
            };
        }
    }

    return {
        taxonomyName: 'Unknown',
        taxonomyTermName: 'Unknown',
    };
}

type TaxonomyTermDisplayProps = {
    taxonomies?: TaxonomyTermDisplay_TaxonomyFragment[] | null;
    taxonomyTermKey: string;
};
export function TaxonomyTermDisplay({taxonomies, taxonomyTermKey}: TaxonomyTermDisplayProps) {
    const {taxonomyName, taxonomyTermName} = getTaxonomyTermDetails(taxonomyTermKey, taxonomies);

    return (
        <Chip>
            <DetailText bold>{taxonomyName}</DetailText>
            <span className="ml-1">{taxonomyTermName}</span>
        </Chip>
    );
}
