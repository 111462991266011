import React from 'react';

import {DetailText} from '@sphericsio/design-system';

import {MerchantDetail_AdminMerchantFragment} from '../../graphql/generated/graphql';

type MerchantAliasesProps = {
    merchant: MerchantDetail_AdminMerchantFragment;
};

export function MerchantAliases({merchant}: MerchantAliasesProps) {
    return (
        <div className="flex flex-col">
            {merchant.aliases &&
                merchant.aliases.map((alias) => (
                    <DetailText key={alias.id}>{alias.name_alias}</DetailText>
                ))}
        </div>
    );
}
