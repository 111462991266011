import React from 'react';
import {BrowserRouter, Route, Routes as RouterRoutes, Navigate} from 'react-router-dom';

import {Layout} from './components/layout';
import {LoggedInRoutes} from './components/logged-in-routes';
import {MergeMerchantContextProvider} from './components/merchants/merge-context';
import {Index} from './views/index';
import {Login} from './views/login';
import {Logout} from './views/logout';
import {UnidentifiedMerchants} from './views/merchants/unidentified';
import {SearchMerchants} from './views/merchants/search';
import {MerchantDetail} from './views/merchant-detail';
import {IdentifyMerchant} from './views/merchants/identify';
import {TransactionsWithoutMerchants} from './views/transactions-without-merchants';
import {SearchCompanies} from './views/companies';
import {CompanyDetail} from './views/company-detail';
import {FinancialProviders} from './views/financial-providers';
import {Users} from './views/users';
import {IdentityProviderCallback} from './components/callback';

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Layout>
                <RouterRoutes>
                    <LoggedInRoutes>
                        <Route path="/" element={<Index />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/transactions" element={<TransactionsWithoutMerchants />} />
                        <Route path="/companies" element={<SearchCompanies />} />
                        <Route path="/companies/detail/:companyId" element={<CompanyDetail />} />
                        <Route path="/financial-providers" element={<FinancialProviders />} />
                        <Route path="/users" element={<Users />} />
                        <MergeMerchantContextProvider>
                            <Route path="*">
                                <Route path="/merchants">
                                    <Navigate to="unidentified" />
                                </Route>
                                <Route
                                    path="/merchants/search/:query"
                                    element={<SearchMerchants />}
                                />
                                <Route path="/merchants/search" element={<SearchMerchants />} />
                                <Route
                                    path="/merchants/unidentified"
                                    element={<UnidentifiedMerchants />}
                                />
                                <Route
                                    path="/merchants/:merchantId/identify"
                                    element={<IdentifyMerchant />}
                                />
                                <Route path="/merchants/:merchantId" element={<MerchantDetail />} />
                            </Route>
                        </MergeMerchantContextProvider>
                    </LoggedInRoutes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/sage-id/callback" element={<IdentityProviderCallback />} />
                </RouterRoutes>
            </Layout>
        </BrowserRouter>
    );
};

export {Routes};
