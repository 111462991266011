import React from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {sortBy} from 'lodash';
import Highlighter from 'react-highlight-words';

import {
    SearchInput,
    FormControl,
    FormErrorMessage,
    Button,
    DetailText,
    Chip,
    Panel,
    HStack,
} from '@sphericsio/design-system';

import {SicCode} from '../../graphql/generated/graphql';
import {GetSicCodesForSearchDocument, SetMerchantSicCodesDocument} from './graphql';

type SearchMatch<T> = {
    key: string;
    item: T;
    entry: string;
    matches: string[];
    matchesKey: boolean;
};

function findMatches<T>(
    data: T[],
    query: string,
    getSearchEntry: (entry: T) => string,
    getSearchKey: (entry: T) => string,
) {
    const terms = query
        .split(' ')
        .filter((term) => term !== '')
        .map((term) => term.toLowerCase());
    const searchMatches = data.reduce((searchMatches, entry) => {
        const searchEntry = getSearchEntry(entry);
        const key = getSearchKey(entry);
        const matches = terms.reduce((matches, term) => {
            if (!searchEntry.toLowerCase().includes(term.toLowerCase())) {
                return matches;
            }

            return matches.concat(term);
        }, [] as string[]);

        if (matches.length === 0) {
            return searchMatches;
        }

        return searchMatches.concat({
            key,
            item: entry,
            entry: searchEntry,
            matches,
            matchesKey: terms.includes(key.toLowerCase()),
        });
    }, [] as SearchMatch<T>[]);

    return sortBy(
        searchMatches,
        (match) => (match.matchesKey ? 1 : 0),
        (match) => -match.matches.length,
        (match) => match.entry,
    );
}

type SearchSicCodesProps = {
    merchantId: string;
    onSuccess: (merchantId: string) => void;
    initialSicCodes?: string[];
};

const SearchSicCodes: React.FC<SearchSicCodesProps> = ({
    merchantId,
    onSuccess,
    initialSicCodes,
}) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState<SearchMatch<SicCode>[]>([]);
    const [selectedCodes, setSelectedCodes] = React.useState<string[]>(initialSicCodes || []);
    const {loading, error, data} = useQuery(GetSicCodesForSearchDocument);
    const [setSicCodes, {loading: submitting, data: result}] = useMutation(
        SetMerchantSicCodesDocument,
    );
    React.useEffect(() => {
        if (result != null) {
            onSuccess(merchantId);
        }
    }, [result, onSuccess, merchantId]);
    const onPerformSearch = React.useRef((query: string) => {
        setSearchTerm(query);
    });

    React.useEffect(() => {
        if (data == null || error) {
            setSearchResults([]);
        } else if (searchTerm === '') {
            setSearchResults([]);
        } else {
            setSearchResults(
                findMatches(
                    data.getSicCodes,
                    searchTerm,
                    (entry) => `(${entry.sic_code}) ${entry.description}`,
                    (entry) => entry.sic_code,
                ),
            );
        }
    }, [data, searchTerm, error]);

    function onClickResult(sicCode: string) {
        const without = selectedCodes.filter((code) => code !== sicCode);
        if (without.length === selectedCodes.length) {
            setSelectedCodes(without.concat(sicCode));
        } else {
            setSelectedCodes(without);
        }
    }

    function onSubmit() {
        if (selectedCodes.length === 0) {
            return;
        }
        setSicCodes({
            variables: {merchantId, sicCodes: selectedCodes},
        });
    }

    return (
        <div>
            <form className="flex flex-col w-1/2 mb-2">
                <FormControl isInvalid={error != null}>
                    <SearchInput
                        name="sicCode"
                        throttleSearch={false}
                        onPerformSearch={onPerformSearch.current}
                        minCharacters={3}
                        loading={loading && searchTerm !== ''}
                    />
                    <FormErrorMessage>Something went wrong.</FormErrorMessage>
                </FormControl>
                <Panel>
                    <div className="flex">
                        <div className="flex flex-col flex-1">
                            <DetailText>Selected SIC Codes:</DetailText>
                            <div className="flex">
                                {selectedCodes.length > 0 && (
                                    <HStack>
                                        {selectedCodes.map((code) => (
                                            <Chip key={code}>
                                                <span
                                                    title={
                                                        data?.getSicCodes.find(
                                                            (sicCode) => sicCode.sic_code === code,
                                                        )?.description || ''
                                                    }
                                                    style={{fontFamily: 'monospace'}}
                                                    onClick={() => onClickResult(code)}
                                                >
                                                    {code}
                                                </span>
                                            </Chip>
                                        ))}
                                    </HStack>
                                )}
                            </div>
                        </div>
                        <div>
                            <Button
                                isDisabled={selectedCodes.length === 0}
                                onPress={onSubmit}
                                isLoading={submitting}
                            >
                                Choose SIC Codes
                            </Button>
                        </div>
                    </div>
                </Panel>
            </form>
            <ul className="divide-y">
                {searchResults.map((result) => (
                    <li
                        key={result.item.sic_code}
                        className="cursor-pointer py-1 space-x-2"
                        onClick={onClickResult.bind(null, result.item.sic_code)}
                    >
                        <Chip>
                            <span style={{fontFamily: 'monospace'}}>{result.item.sic_code}</span>
                        </Chip>
                        <Highlighter
                            textToHighlight={result.item.description}
                            searchWords={result.matches}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export {SearchSicCodes};
