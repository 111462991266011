import React from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import {
    SearchInput,
    FormControl,
    FormErrorMessage,
    Button,
    Panel,
    Heading2,
    DetailText,
    VStack,
} from '@sphericsio/design-system';

import {SearchCompaniesHouseDocument, SetCompaniesHouseIdForMerchantDocument} from './graphql';

type SearchCompaniesHouseProps = {
    merchantId: string;
    onSuccess: (merchantId: string) => void;
};

const SearchCompaniesHouse: React.FC<SearchCompaniesHouseProps> = ({merchantId, onSuccess}) => {
    const [query, setQuery] = React.useState('');
    const [selectedCompanyNumber, setSelectedCompanyNumber] = React.useState<string>();
    const [search, {loading, error, data}] = useLazyQuery(SearchCompaniesHouseDocument);
    const onPerformSearch = React.useRef((query: string) => {
        setQuery(query);
    });
    const [setCompaniesHouseId, {data: setCompaniesHouseIdData}] = useMutation(
        SetCompaniesHouseIdForMerchantDocument,
    );
    React.useEffect(() => {
        if (selectedCompanyNumber != null) {
            setCompaniesHouseId({variables: {merchantId, companyNumber: selectedCompanyNumber}});
        }
    }, [selectedCompanyNumber, merchantId, setCompaniesHouseId]);

    const navigate = useNavigate();
    React.useEffect(() => {
        if (setCompaniesHouseIdData != null) {
            onSuccess(merchantId);
        }
    }, [setCompaniesHouseIdData, navigate, merchantId]);

    const performSearch = (e?: React.BaseSyntheticEvent) => {
        if (e != null) {
            e.preventDefault();
        }
        if (query === '') {
            return;
        }

        search({variables: {query}});
    };
    return (
        <div className="w-full">
            <form onSubmit={performSearch}>
                <FormControl isInvalid={error != null}>
                    <SearchInput
                        name="companyName"
                        minCharacters={1}
                        throttleSearch={false}
                        onPerformSearch={onPerformSearch.current}
                        loading={loading}
                        placeholder="Search Companies House"
                        onPressSearchIcon={() => performSearch()}
                    />
                    <FormErrorMessage>Something went wrong.</FormErrorMessage>
                </FormControl>
            </form>
            <div className="flex">
                <div className="w-full">
                    <VStack>
                        {data &&
                            data.searchCompaniesHouse.map((result) => (
                                <Panel key={result.company_number}>
                                    <div className="mb-2">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`https://find-and-update.company-information.service.gov.uk/company/${result.company_number}`}
                                        >
                                            <Heading2>{result.title}</Heading2>
                                        </a>
                                        <DetailText inline={false}>
                                            <div className="space-y-2">
                                                <div>Company status: {result.company_status}</div>
                                                <div>Company type: {result.company_type}</div>
                                                <div>Created: {result.date_of_creation}</div>
                                                <div>Dissolved: {result.date_of_cessation}</div>
                                            </div>
                                        </DetailText>
                                    </div>
                                    <Button
                                        isDisabled={selectedCompanyNumber != null}
                                        isLoading={selectedCompanyNumber === result.company_number}
                                        onPress={() => {
                                            setSelectedCompanyNumber(result.company_number);
                                        }}
                                    >
                                        Select this company
                                    </Button>
                                </Panel>
                            ))}
                    </VStack>
                </div>
            </div>
        </div>
    );
};

export {SearchCompaniesHouse};
