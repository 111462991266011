import React from 'react';

import {Modal} from './modal';
import {EditMerchantTaxonomies} from '../merchants/edit-merchant-taxonomies';
import {MerchantTaxonomy} from '../../graphql/generated/graphql';

type EditMerchantTaxonomiesModalProps = {
    merchantId?: string;
    merchantTaxonomyTerms: MerchantTaxonomy;
    onClose: () => void;
    onSuccess: (merchantId: string) => void;
};

export function EditMerchantTaxonomiesModal({
    merchantId,
    merchantTaxonomyTerms,
    onClose,
    onSuccess,
}: EditMerchantTaxonomiesModalProps) {
    return (
        <Modal
            title="Edit Merchant Financial Categories"
            isOpen={merchantId != null}
            onClose={onClose}
        >
            {merchantId && (
                <EditMerchantTaxonomies
                    merchantId={merchantId}
                    merchantTaxonomyTerms={merchantTaxonomyTerms}
                    onSuccess={onSuccess}
                />
            )}
        </Modal>
    );
}
