import React from 'react';
import {useLazyQuery} from '@apollo/client';
import {useParams, useNavigate, Link} from 'react-router-dom';

import {
    SearchInput,
    Panel,
    DetailText,
    FormControl,
    FormErrorMessage,
    VStack,
    ChevronRightIcon,
} from '@sphericsio/design-system';

import {MerchantTabs} from '../../components/merchants/tabs';
import {graphql} from '../../graphql/generated';

const SearchForMerchantDocument = graphql(/* GraphQL */ `
    query SearchForMerchant($query: String!) {
        searchForMerchant(query: $query) {
            id
            name
        }
    }
`);

type SearchEntryProps = {
    merchant: {id: string; name: string};
    currentQuery: string;
};
function SearchEntry({merchant, currentQuery}: SearchEntryProps) {
    return (
        <Panel>
            <Link
                className="inline-block w-full"
                to={`/merchants/${merchant.id}`}
                state={{query: currentQuery}}
            >
                <div className="flex items-center">
                    <ChevronRightIcon />
                    <DetailText>{merchant.name}</DetailText>
                </div>
            </Link>
        </Panel>
    );
}

const SearchMerchants: React.FC = () => {
    const [search, {loading, error, data}] = useLazyQuery(SearchForMerchantDocument);
    const [searchResults, setSearchResults] = React.useState(data);
    const {query} = useParams();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (query != null) {
            search({variables: {query}});
        }
    }, [query, search]);
    React.useEffect(() => {
        setSearchResults(data);
    }, [data, setSearchResults]);
    const onPerformSearch = React.useRef((query: string) => {
        navigate(`/merchants/search/${query}`, {replace: true});
    });
    return (
        <MerchantTabs tab="search">
            <FormControl isInvalid={error != null}>
                <SearchInput
                    name="search"
                    minCharacters={3}
                    onPerformSearch={onPerformSearch.current}
                    initialSearch={query}
                    loading={loading}
                />
                <FormErrorMessage>Something went wrong. Try again later</FormErrorMessage>
            </FormControl>
            <VStack>
                {searchResults && (
                    <>
                        {searchResults.searchForMerchant.map((merchant) => (
                            <SearchEntry
                                key={merchant.id}
                                merchant={merchant}
                                currentQuery={query}
                            />
                        ))}
                        {searchResults.searchForMerchant.length === 0 && (
                            <Panel>
                                <DetailText>No merchants found.</DetailText>
                            </Panel>
                        )}
                    </>
                )}
            </VStack>
        </MerchantTabs>
    );
};

export {SearchMerchants};
