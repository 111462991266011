import React from 'react';

import {DetailText} from '@sphericsio/design-system';

import {
    TaxonomyTermDisplay,
    TaxonomyTermDisplayTaxonomyFragmentDocument,
} from '../taxonomy-term-display';
import {FragmentType, useFragment} from '../../graphql/generated';
import {TaxonomyTermDisplay_TaxonomyFragment} from '../../graphql/generated/graphql';
import {FinancialAccountDisplayAdminTransactionFragmentDocument} from './fragments';

type FinancialAccountDisplayProps = {
    transaction: FragmentType<typeof FinancialAccountDisplayAdminTransactionFragmentDocument>;
    taxonomies?: FragmentType<typeof TaxonomyTermDisplayTaxonomyFragmentDocument>[];
};
export function FinancialAccountDisplay(props: FinancialAccountDisplayProps) {
    const transaction = useFragment(
        FinancialAccountDisplayAdminTransactionFragmentDocument,
        props.transaction,
    );

    const taxonomies = useFragment(TaxonomyTermDisplayTaxonomyFragmentDocument, props.taxonomies);

    return (
        <div className="flex items-center">
            <DetailText>
                <span className="mr-2" title={transaction.financial_account.description}>
                    {transaction.financial_account.name}
                </span>
            </DetailText>
            {transaction.financial_account.terms.map((taxonomyTerm) => (
                <TaxonomyTermDisplay
                    key={taxonomyTerm.id}
                    taxonomyTermKey={taxonomyTerm.id}
                    taxonomies={taxonomies as TaxonomyTermDisplay_TaxonomyFragment[]}
                />
            ))}
        </div>
    );
}
