import React from 'react';
import {useMutation} from '@apollo/client';

import {Matcher} from '../../graphql/generated/graphql';
import {MatcherRow} from '../matchers/matcher-row';
import {UpdateMerchantMatcherDocument} from './graphql';

type MerchantMatcherRowProps = {
    matcher: Matcher;
};
const MerchantMatcherRow: React.FC<MerchantMatcherRowProps> = ({matcher}) => {
    const [updateMatcher, {loading}] = useMutation(UpdateMerchantMatcherDocument);
    return (
        <MatcherRow
            matcher={matcher}
            updateMatcher={(id, enabled) => updateMatcher({variables: {params: {id, enabled}}})}
            loading={loading}
        />
    );
};

export {MerchantMatcherRow};
