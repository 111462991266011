import {graphql} from '../../graphql/generated';

export const AddMatcherToMerchantDocument = graphql(/* GraphQL */ `
    mutation AddMatcherToMerchant($merchantId: ID!, $matcher: AddMatcher!) {
        addMatcherToMerchant(merchantId: $merchantId, matcher: $matcher) {
            id
            matchers {
                id
                query
                enabled
                notes
            }
        }
    }
`);

export const AddMerchantCarbonIntensityDocument = graphql(/* GraphQL */ `
    mutation AddMerchantCarbonIntensity($params: AddMerchantCarbonIntensityParams!) {
        addMerchantCarbonIntensity(params: $params) {
            ...MerchantDetail_AdminMerchant
        }
    }
`);

export const RemoveMerchantCarbonIntensityDocument = graphql(/* GraphQL */ `
    mutation RemoveMerchantCarbonIntensity($merchantId: ID!, $carbonIntensityId: ID!) {
        removeMerchantCarbonIntensity(merchantId: $merchantId, id: $carbonIntensityId) {
            id
        }
    }
`);
export const FetchAllTaxonomiesDocument = graphql(/* GraphQL */ `
    query FetchAllTaxonomies {
        getAllTaxonomies {
            id
            name
            terms {
                id
                name
                description
                taxonomyKey
            }
        }
    }
`);

export const EditMerchantTaxonomyTermsDocument = graphql(/* GraphQL */ `
    mutation EditMerchantTaxonomyTerms(
        $merchantId: ID!
        $overrideTaxonomyTerm: String
        $suggestedTaxonomyTerm: String
    ) {
        editMerchantTaxonomyTerms(
            merchantId: $merchantId
            overrideTaxonomyTerm: $overrideTaxonomyTerm
            suggestedTaxonomyTerm: $suggestedTaxonomyTerm
        ) {
            id
        }
    }
`);

export const UpdateMerchantMatcherDocument = graphql(/* GraphQL */ `
    mutation UpdateMerchantMatcher($params: UpdateMatcher!) {
        updateMatcherForMerchant(params: $params) {
            id
            matchers {
                id
                query
                enabled
                notes
            }
        }
    }
`);

export const MergeMerchantDocument = graphql(/* GraphQL */ `
    mutation MergeMerchant($id: ID!, $mergeWithMerchantId: ID!) {
        mergeMerchant(id: $id, mergeWithMerchantId: $mergeWithMerchantId) {
            ...MerchantDetail_AdminMerchant
        }
    }
`);

export const SetMerchantMetadataFlagDocument = graphql(/* GraphQL */ `
    mutation SetMerchantMetadataFlag($params: SetMerchantMetadata!) {
        setMerchantMetadataItem(params: $params) {
            id
            metadata {
                id
                metadata_type
                metadata_value
            }
        }
    }
`);

export const SearchCompaniesHouseDocument = graphql(/* GraphQL */ `
    query SearchCompaniesHouse($query: String!) {
        searchCompaniesHouse(query: $query) {
            company_number
            company_status
            company_type
            date_of_creation
            date_of_cessation
            description
            title
        }
    }
`);

export const SetCompaniesHouseIdForMerchantDocument = graphql(/* GraphQL */ `
    mutation SetCompaniesHouseIdForMerchant($merchantId: ID!, $companyNumber: String!) {
        setCompaniesHouseIdForMerchant(merchantId: $merchantId, companyNumber: $companyNumber) {
            ...MerchantDetail_AdminMerchant
        }
    }
`);

export const GetSicCodesForSearchDocument = graphql(/* GraphQL */ `
    query GetSicCodesForSearch {
        getSicCodes {
            sic_code
            description
        }
    }
`);

export const SetMerchantSicCodesDocument = graphql(/* GraphQL */ `
    mutation SetMerchantSicCodes($merchantId: ID!, $sicCodes: [String!]!) {
        setMerchantSicCodes(merchantId: $merchantId, sicCodes: $sicCodes) {
            ...MerchantDetail_AdminMerchant
        }
    }
`);

export const FetchMerchantTransactionsDocument = graphql(/* GraphQL */ `
    query FetchMerchantTransactions(
        $merchantId: ID!
        $first: Int
        $after: String
        $last: Int
        $before: String
        $orderBy: TransactionOrder!
        $orderDirection: SortDirection!
    ) {
        getAdminMerchantById(id: $merchantId) {
            id
            transactions(
                first: $first
                after: $after
                last: $last
                before: $before
                orderBy: $orderBy
                orderDirection: $orderDirection
            ) {
                pageInfo {
                    hasNextPage
                    endCursor
                    hasPreviousPage
                    startCursor
                }
                edges {
                    node {
                        id
                        description
                        amount
                        transaction_date
                        ...FinancialAccountDisplay_AdminTransaction
                    }
                }
            }
        }
    }
`);

export const GetAllTaxonomiesDocument = graphql(/* GraphQL */ `
    query GetAllTaxonomiesForTransactions {
        getAllTaxonomies {
            ...TaxonomyTermDisplay_Taxonomy
        }
    }
`);
