import React from 'react';

import {Heading1, Heading2, Panel, Chip, HStack, VStack} from '@sphericsio/design-system';

import {MetadataFlags} from './metadata-flags';
import {MergeMerchantButton} from './merged';
import {MerchantSicCodes} from './sic-codes';
import {MerchantAliases} from './aliases';
import {MerchantCarbonIntensities} from './carbon-intensities';
import {MerchantTaxonomies} from './taxonomies';
import {MerchantBreadcrumbLink} from './breadcrumb-link';
import {
    MerchantDetail_AdminMerchantFragment,
    TaxonomyTermDisplay_TaxonomyFragment,
} from '../../graphql/generated/graphql';

const MerchantHeaderPanel: React.FC<{title: string; children: React.ReactNode}> = ({
    title,
    children,
}) => {
    return (
        <Panel>
            <div className="flex flex-col h-full">
                <Heading2>{title}</Heading2>
                {children}
            </div>
        </Panel>
    );
};

type MerchantHeaderProps = {
    merchant: MerchantDetail_AdminMerchantFragment;
    taxonomies: TaxonomyTermDisplay_TaxonomyFragment[];
};

export function MerchantHeader({merchant, taxonomies}: MerchantHeaderProps) {
    return (
        <div className="flex flex-col">
            <MerchantBreadcrumbLink merchant={merchant} />
            <HStack>
                <div className="inline-block">
                    <div className="mb-2">
                        <Heading1>{merchant.name}</Heading1>
                    </div>
                    {!merchant.identified && <Chip>Unidentified</Chip>}
                </div>
                <div>
                    <HStack>
                        <MergeMerchantButton merchant={merchant} />
                    </HStack>
                </div>
            </HStack>
            <VStack>
                <HStack>
                    <MerchantHeaderPanel title="SIC Codes">
                        <MerchantSicCodes merchant={merchant} />
                    </MerchantHeaderPanel>
                    <MerchantHeaderPanel title="Carbon Intensities">
                        <MerchantCarbonIntensities merchant={merchant} />
                    </MerchantHeaderPanel>
                    <MerchantHeaderPanel title="Taxonomy">
                        <MerchantTaxonomies merchant={merchant} taxonomies={taxonomies} />
                    </MerchantHeaderPanel>
                </HStack>
                <HStack>
                    <MerchantHeaderPanel title="Flags">
                        <MetadataFlags merchant={merchant} />
                    </MerchantHeaderPanel>
                    <MerchantHeaderPanel title="Also known as">
                        <MerchantAliases merchant={merchant} />
                    </MerchantHeaderPanel>
                </HStack>
            </VStack>
        </div>
    );
}
