import React from 'react';
import {useMutation} from '@apollo/client';

import {Button} from '@sphericsio/design-system';

import {ButtonLink} from '../link';
import {useMergeMerchantContext, MerchantToMerge} from './merge-context';
import {MergeMerchantDocument} from './graphql';

type MergeMerchantButtonProps = {
    merchant: MerchantToMerge;
};

const MergeMerchantButton: React.FC<MergeMerchantButtonProps> = ({merchant}) => {
    const [mergeMerchant, {loading}] = useMutation(MergeMerchantDocument);
    const {merchantToMerge, clearMerchantToMerge, setMerchantToMerge} = useMergeMerchantContext();
    if (merchantToMerge != null) {
        return (
            <Button
                isLoading={loading}
                onPress={() => {
                    mergeMerchant({
                        variables: {id: merchantToMerge.id, mergeWithMerchantId: merchant.id},
                    }).then(() => {
                        clearMerchantToMerge();
                    });
                }}
            >
                Merge {merchantToMerge.name} into this merchant
            </Button>
        );
    } else if (!merchant.identified) {
        return (
            <ButtonLink to={'/merchants/search'} onPress={() => setMerchantToMerge(merchant)}>
                Merge with another Merchant
            </ButtonLink>
        );
    } else {
        return null;
    }
};

export {MergeMerchantButton};
