import React from 'react';
import {useLocation} from 'react-router-dom';

import {DetailText} from '@sphericsio/design-system';

import {Link} from '../link';
import {MerchantDetail_AdminMerchantFragment} from '../../graphql/generated/graphql';

type MerchantBreadcrumbLinkProps = {
    merchant: Pick<MerchantDetail_AdminMerchantFragment, 'id' | 'name'>;
};

export function MerchantBreadcrumbLink({merchant}: MerchantBreadcrumbLinkProps) {
    const location = useLocation() as {state?: {query?: string}};
    const lastActiveSearch = location.state?.query;
    return (
        <div className="space-x-1">
            <Link to={lastActiveSearch ? `/merchants/search/${lastActiveSearch}` : '/merchants'}>
                Merchants
            </Link>
            <DetailText>/</DetailText>
            <Link active to={`/merchants/${merchant.id}`}>
                {merchant.name}
            </Link>
        </div>
    );
}
