import React from 'react';
import {useMutation} from '@apollo/client';
import classnames from 'classnames';

import {DetailText, Link} from '@sphericsio/design-system';

import {AddMerchantCarbonIntensityModal} from '../modals/add-merchant-carbon-intensity-modal';
import {MerchantHeaderPanel} from './header-panel';
import {MerchantDetail_AdminMerchantFragment} from '../../graphql/generated/graphql';
import {RemoveMerchantCarbonIntensityDocument} from './graphql';

type MerchantCarbonIntensitiesProps = {
    merchant: MerchantDetail_AdminMerchantFragment;
};
export function MerchantCarbonIntensities({merchant}: MerchantCarbonIntensitiesProps) {
    const [modalMerchantId, setModalMerchantId] = React.useState<string>();
    const [removeCarbonIntensity, {loading}] = useMutation(RemoveMerchantCarbonIntensityDocument);
    const onAdd = () => {
        setModalMerchantId(merchant.id);
    };
    const onClose = () => {
        setModalMerchantId(undefined);
    };
    return (
        <MerchantHeaderPanel buttonLabel="Add" onPressButton={onAdd}>
            <>
                {merchant.carbonIntensities.map((carbonIntensity) => (
                    <div
                        className={classnames('flex items-center justify-between', {
                            'bg-utility-major-75 cursor-not-allowed': loading,
                        })}
                        key={carbonIntensity.id}
                    >
                        <a href={carbonIntensity.reference_url}>
                            <DetailText>
                                {carbonIntensity.carbon_intensity} ({carbonIntensity.year})
                            </DetailText>
                        </a>
                        <Link
                            href="#"
                            onPress={() => {
                                removeCarbonIntensity({
                                    variables: {
                                        carbonIntensityId: carbonIntensity.id,
                                        merchantId: merchant.id,
                                    },
                                });
                            }}
                        >
                            Remove
                        </Link>
                    </div>
                ))}
                <AddMerchantCarbonIntensityModal
                    merchantId={modalMerchantId}
                    onClose={onClose}
                    onSuccess={onClose}
                />
            </>
        </MerchantHeaderPanel>
    );
}
