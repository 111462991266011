import React from 'react';

import {Modal} from './modal';
import {AddMerchantCarbonIntensity} from '../merchants/add-merchant-carbon-intensity';

type AddMerchantCarbonIntensityModalProps = {
    merchantId?: string;
    onClose: () => void;
    onSuccess: (merchantId: string) => void;
};

const AddMerchantCarbonIntensityModal: React.FC<AddMerchantCarbonIntensityModalProps> = ({
    merchantId,
    onClose,
    onSuccess,
}) => {
    return (
        <Modal title="Add Merchant Carbon Intensity" isOpen={merchantId != null} onClose={onClose}>
            {merchantId && (
                <AddMerchantCarbonIntensity merchantId={merchantId} onSuccess={onSuccess} />
            )}
        </Modal>
    );
};

export {AddMerchantCarbonIntensityModal};
