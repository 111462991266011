import {graphql} from '../../graphql/generated';

export const FinancialAccountDisplayAdminTransactionFragmentDocument = graphql(/* GraphQL */ `
    fragment FinancialAccountDisplay_AdminTransaction on AdminTransaction {
        financial_account {
            name
            description
            terms {
                id
                name
                taxonomyKey
            }
        }
    }
`);
