import React from 'react';
import {useQuery} from '@apollo/client';

import {Heading2} from '@sphericsio/design-system';
import {relayConnectionQuery, RelayConnectionVariables} from '@sphericsio/mvp-ui-common';

import {DataTable} from '../table';
import {moneyAsText} from '../money';
import {FinancialAccountDisplay} from './financial-account-display';
import {FetchMerchantTransactionsQuery} from '../../graphql/generated/graphql';
import {FetchMerchantTransactionsDocument, GetAllTaxonomiesDocument} from './graphql';

type MerchantTransactionsProps = {
    merchantId: string;
};

const MerchantTransactions: React.FC<MerchantTransactionsProps> = ({merchantId}) => {
    const {data} = useQuery(GetAllTaxonomiesDocument);
    const queryConfig = relayConnectionQuery(
        FetchMerchantTransactionsDocument,
        (result: FetchMerchantTransactionsQuery) => result.getAdminMerchantById.transactions,
        {merchantId} as Partial<RelayConnectionVariables>,
    );

    return (
        <div className="flex grow flex-col mb-4">
            <Heading2>Transactions</Heading2>
            <DataTable
                queryConfig={queryConfig}
                columns={[
                    {label: 'Description', key: 'description'},
                    {label: 'Amount', key: 'amount', renderValue: (value) => moneyAsText(value)},
                    {label: 'Date', key: 'transaction_date'},
                    {
                        label: 'Account',
                        // eslint-disable-next-line react/display-name
                        render: (value) => (
                            <FinancialAccountDisplay
                                transaction={value}
                                taxonomies={data?.getAllTaxonomies}
                            />
                        ),
                    },
                ]}
                sortableFields={['transaction_date', 'amount', 'description']}
            />
        </div>
    );
};

export {MerchantTransactions};
