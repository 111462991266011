import React from 'react';

import {HStack, Strong, VStack} from '@sphericsio/design-system';

import {EditMerchantTaxonomiesModal} from '../modals/edit-merchant-financial-categories-modal';
import {MerchantHeaderPanel} from './header-panel';
import {TaxonomyTermDisplay} from '../taxonomy-term-display';
import {
    MerchantDetail_AdminMerchantFragment,
    TaxonomyTermDisplay_TaxonomyFragment,
} from '../../graphql/generated/graphql';

type MerchantTaxonomiesProps = {
    merchant: MerchantDetail_AdminMerchantFragment;
    taxonomies: TaxonomyTermDisplay_TaxonomyFragment[];
};

export function MerchantTaxonomies({merchant, taxonomies}: MerchantTaxonomiesProps) {
    const [modalMerchantId, setModalMerchantId] = React.useState<string>();
    const onAdd = () => {
        setModalMerchantId(merchant.id);
    };
    const onClose = () => {
        setModalMerchantId(undefined);
    };

    const taxonomyTerms = merchant.taxonomy || [];
    const overridden = taxonomyTerms.overrideTaxonomyTerm;
    const suggested = taxonomyTerms.suggestedTaxonomyTerm;
    return (
        <MerchantHeaderPanel buttonLabel="Edit" onPressButton={onAdd}>
            <VStack>
                {overridden != null && (
                    <div className="flex flex-col">
                        <Strong>Overridden</Strong>
                        <HStack wrap>
                            <TaxonomyTermDisplay
                                taxonomyTermKey={overridden}
                                taxonomies={taxonomies}
                            />
                        </HStack>
                    </div>
                )}
                {suggested != null && (
                    <div className="flex flex-col">
                        <Strong>Suggested</Strong>
                        <HStack wrap>
                            <TaxonomyTermDisplay
                                taxonomyTermKey={suggested}
                                taxonomies={taxonomies}
                            />
                        </HStack>
                    </div>
                )}
            </VStack>
            <EditMerchantTaxonomiesModal
                merchantId={modalMerchantId}
                merchantTaxonomyTerms={taxonomyTerms}
                onClose={onClose}
                onSuccess={onClose}
            />
        </MerchantHeaderPanel>
    );
}
